<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4" sm="4" md="4">
            <v-text-field
              v-if="dataSet.title"
              disabled
              :value="dataSet.title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Доступ</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-btn
              text
              small
              :color="!dataSet.is_open ? 'primary' : 'error'"
              @click="dataSet.is_open = !dataSet.is_open"
            >
              <span v-if="!dataSet.is_open">Открыть</span>
              <span v-else>Закрыть</span>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Просмотр календаря</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-btn
              text
              small
              :color="dataSet.calendar_view ? 'primary' : 'error'"
              @click="dataSet.calendar_view = !dataSet.calendar_view"
            >
              <span v-if="dataSet.calendar_view">Да</span>
              <span v-else>Нет</span>
            </v-btn>
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Форма бронирования</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-select
              dense
              v-model="dataSet.booking_form"
              :items="booking_forms"
            />
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Лимит на продажу</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-select
              disabled
              v-model="dataSet.sales_limit_type"
              dense
              :items="limit_types"
            />
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Значение лимита на продажу</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-text-field
              disabled
              dense
              type="number"
              v-model="dataSet.sales_limit_value"
            />
          </v-col>
          <v-col cols="4" class="pb-0 pt-0">
            <p class="col-title">Комиссия</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-text-field dense type="number" v-model="dataSet.commission" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="save" :disabled="disabledBtn">
          Сохранить
        </v-btn>
        <v-btn color="primary" @click="$router.push('/list')"> Выход </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import user from "../../../store/auth";
import toast from "../../../plugins/toast";
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";

export default {
  name: "SalesSettings",
  data() {
    return {
      dialog: false,
      disabledBtn: false,
      dataSet: {
        id: null,
        channel_type: "easybook",
        title: "",
        city: "",
        contact_person: "",
        phone_number: "",
        email: "",
        _ie: "",
        _bik: "",
        legal_address: "",
        post_code: "",
        commission: 10,
        due_date: "",
        calendar_view: false,
        booking_form: "request",
        status_permission: "reserve",
        sales_limit_type: "percent",
        sales_limit_value: 10,
        tour_operator: null,
        company: Number(user.getters.getCompany),
        blocks: []
      },
      booking_forms: [
        {
          text: "Указать на шахматке",
          value: "grid"
        },
        {
          text: "Заявка",
          value: "request"
        }
      ],
      limit_types: [
        {
          text: "%",
          value: "percent"
        },
        {
          text: "Количество",
          value: "quantity"
        }
      ]
    };
  },
  created() {
    this.loadSale();
  },
  methods: {
    async save() {
      if (!this.dataSet.id) {
        toast.error("Выберите канал продаж");
        return;
      }
      this.disabledBtn = true;
      try {
        await SalesApiUrls.setSale(this.dataSet.id, this.dataSet);
        toast.success("Успешно изменилось!");
      } finally {
        this.disabledBtn = false;
      }
    },
    async loadSale() {
      let res = await SalesApiUrls.getSale(this.$route.query.id);
      this.dataSet = res;
    }
  }
};
</script>
<style>
.col-title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
